.hidden {
  display: none !important;
}

.repeated-image {
  /* width: 300px; 
  height: 300px;  */
  background-image: url('./assets/image/footer.png'); /* Replace with your image URL */
  background-repeat: repeat; /* Repeat the image both horizontally and vertically */
}

.single-date {
  background-color: #f9f9f9 !important;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35) !important;
  z-index: 9999;
}

.bg-checkered {
  background-image: linear-gradient(45deg, #444 25%, transparent 25%, transparent 75%, #444 75%, #444),
                    linear-gradient(45deg, #444 25%, transparent 25%, transparent 75%, #444 75%, #444);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}

.select__control {
  background-color: #F0F0F0 !important;
  border: none !important;
  box-shadow: none !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes wobble {
  0%,
  100% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(65%);
  }
}

.animate-spin {
  animation: spin 1.3s infinite linear;
}

.animate-wobble {
  animation: wobble 1.3s infinite ease-in-out;
}